import {useState} from 'react';

const useUltyModalWrapper =  () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalSettings, setModalSettings] = useState(null);

    const handleUltyModalWrapper = (isNowOpen, nowSettings) => {
        setIsOpen(isNowOpen);
        setModalSettings(nowSettings);
    };

    return { isOpen, handleUltyModalWrapper, modalSettings };
};

export default useUltyModalWrapper;