/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect } from 'react'
import { Tab } from 'semantic-ui-react'
import {
    Route,
    NavLink,
} from 'react-router-dom'
import AdminAccount from './AdminAccounts'
import AdminUsers from './AdminUsers'
import AdminDevice from './AdminDevice'
import AdminPlatforms from './AdminPlatforms'
import AdminMerchant from './AdminMerchant.js'
import useUser from '../Shared/UserProvider/useUser'
import AdminHours from './AdminHours'
import { useTranslation } from 'react-i18next'
import useBreadcrumb from '../NavBar/useBreadcrumb'
import AdminBilling from './AdminBilling/AdminBilling'
import AdminBrands from './AdminBrands/AdminBrands'
import ActivateBusyMode from '../Orders/ActivateBusyMode'

const AdminIndex = () => {
    const [t] = useTranslation()
    const {user, can} = useUser()
    const {setPaths} = useBreadcrumb()

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/',
        }, {
            text: t('breadcrumb.settings'),
            link: false,
        }])
    }, [])

    const isMobileDevice = () => {
        return (window.Android && window.Android.getToken) || window.webkit?.messageHandlers
    }

    const panes = [
        {
            menuItem: {
                as: NavLink,
                id: 'myAccount',
                content: t('administration.submenus.myAccount'),
                to: '/settings',
                exact: true,
                key: 'account',
            },
            pane: (
              <Route
                path="/settings"
                exact
                key="account"
                render={() => (
                  <AdminAccount/>
                )}
              />
            ),
        },
        ...(isMobileDevice() ? [{
            menuItem: {
                as: NavLink,
                id: 'manageDevice',
                content: t('administration.submenus.manageDevice'),
                to: '/settings/device',
                exact: true,
                key: 'device',
            },
            pane: (
              <Route
                path="/settings/device"
                exact
                key="device"
                render={() => (
                  <AdminDevice/>
                )}
              />
            ),
        }] : []),
        ...(can('STATIC', 'administration') ? [{
            menuItem: {
                as: NavLink,
                id: 'manageUsers',
                content: t('administration.submenus.manageUsers'),
                to: `/settings/${user.provider.id}/users`,
                exact: true,
                key: 'users',
            },
            pane: (
              <Route
                path="/settings/:providerId/users"
                exact
                key="users"
                render={() => (
                  <AdminUsers/>
                )}
              />
            ),
        }, {
            menuItem: {
                as: NavLink,
                id: 'managePlatforms',
                content: t('administration.submenus.managePlatforms'),
                to: '/settings/platforms',
                exact: true,
                key: 'platforms',
            },
            pane: (
              <Route
                path="/settings/platforms"
                exact
                key="platforms"
                render={() => (
                  <AdminPlatforms/>
                )}
              />
            ),
        },
            ...(user.provider?.type === 'COMPANY' ? [{
                menuItem: {
                    as: NavLink,
                    id: 'manageBrands',
                    content: t('administration.submenus.manageBrands'),
                    to: '/settings/brands',
                    exact: true,
                    key: 'brands',
                },
                pane: (
                  <Route
                    path="/settings/brands"
                    exact
                    key="brands"
                    render={() => (
                      <AdminBrands/>
                    )}
                  />
                ),
            }] : []), {
                menuItem: {
                    as: NavLink,
                    id: 'manageMerchant',
                    content: t('administration.submenus.manageMerchant'),
                    to: '/settings/merchant',
                    exact: true,
                    key: 'manageMerchant',
                },
                pane: (
                  <Route
                    path="/settings/merchant"
                    exact
                    key="manageMerchant"
                    render={() => (
                      <AdminMerchant/>
                    )}
                  />
                ),
            }, {
                menuItem: {
                    as: NavLink,
                    id: 'manageHours',
                    content: t('administration.submenus.manageHours'),
                    to: '/settings/hours',
                    exact: true,
                    key: 'manageHours',
                },
                pane: (
                  <Route
                    path="/settings/hours"
                    exact
                    key="manageHours"
                    render={() => (
                      <AdminHours/>
                    )}
                  />
                ),
            }, {
                menuItem: {
                    as: NavLink,
                    id: 'manageBilling',
                    content: t('administration.submenus.manageBilling'),
                    to: '/settings/billing',
                    exact: true,
                    key: 'manageBilling',
                },
                pane: (
                  <Route
                    path="/settings/billing"
                    exact
                    key="manageBilling"
                    render={() => (
                      <AdminBilling/>
                    )}
                  />
                ),
            }] : []),
    ]

    return (
      <>
          <ActivateBusyMode />
          <Tab
            css={css`
            margin-top: 20px;
        `}
            renderActiveOnly={false}
            activeIndex={-1}
            panes={panes}
            menu={{secondary: true, pointing: true, className: 'wrapped'}}
          />
      </>
    )
}

export default AdminIndex
