/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Button } from 'semantic-ui-react'
import { notify } from 'react-notify-toast'
import OrderStore from './OrderStore'
import { setBusyMode } from '../../services/PointOfSale/PointOfSale.service'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import useUser from '../Shared/UserProvider/useUser'
import { isProviderACompany } from '../../services/Provider/Provider.service'
import { useContext } from 'react'
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext'

const ActivateBusyMode = () => {
    const [t] = useTranslation()
    const {user} = useUser()
    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext)
    const orderStore = OrderStore.useContainer()
    const updateBusyModeMutation = useMutation({
        mutationFn: () => setBusyMode({status: orderStore.pausedAt ? 'IDLE' : 'BUSY'}),
        onSuccess: (data) => {
            orderStore.setPausedAt(data.pausedAt)
        },
        onError: () => {
            notify.show(t('global.anErrorOccurred'), 'error')
        },
    })

    const defineModal = () => {
        const modalSettings = {
            size: 'tiny',
            title: t('navbar.busy_mode.confirm_activate_busy_mode'),
            component: (
              <div
                css={css`
                    text-align: right;
                `}
              >
                  <Button
                    fluid
                    positive
                    css={css`
                        &.ui.button {
                            margin-bottom: 1rem;
                        }
                    `}
                    onClick={() => {
                        updateBusyModeMutation.mutate()
                        handleUltyModalWrapper(false, null)
                    }}>
                      {t('global.yes')}
                  </Button>

                  <Button
                    fluid
                    onClick={() => {
                        handleUltyModalWrapper(false, null)
                    }}>
                      {t('global.cancel')}
                  </Button>
              </div>
            ),
        }

        handleUltyModalWrapper(true, modalSettings)
    }

    if (isProviderACompany(user?.provider) || orderStore.pausedAt !== null) {
        return null
    }

    return (
      <div css={css`
          display: flex;
          justify-content: flex-end;
      `}>
          <Button
            css={css`
                &.ui.button {
                    margin: 0;
                }
            `}
            size="tiny"
            color="teal"
            basic
            onClick={defineModal}
            disabled={updateBusyModeMutation.isPending}
            loading={updateBusyModeMutation.isPending}>
              {t('navbar.busy_mode.activate_busy_mode')}
          </Button>
      </div>
    )
}

export default ActivateBusyMode
