import { Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next'

const displayableSiteStatuses = ['OPEN', 'CLOSED', 'OPENING_HOURS_ERROR']

const getStatusColor = status => {
    if (status === 'OPEN') return 'teal'
    if (status === 'CLOSED') return 'red'
    return 'yellow'
}

const SiteStatus = ({siteStatus, className}) => {
    const [t] = useTranslation()
    if (!displayableSiteStatuses.includes(siteStatus)) {
        return (
          <span className={className}>
            <Label>
                {t('pos_brand_platform.siteStatus.configuration_issue')}
            </Label>
          </span>)
    }

    return (
      <span className={className}>
        <Label color={getStatusColor(siteStatus)}>
            {t(`pos_brand_platform.siteStatus.${siteStatus.toLowerCase()}`)}
        </Label>
      </span>
    )
}

export { SiteStatus }
