/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import { useState, useEffect } from 'react'

import { Item, Button, Checkbox, Form, Input } from 'semantic-ui-react'

import { notify } from 'react-notify-toast';
import { useTranslation } from 'react-i18next';
import {patchPosBrandPlatform} from '../../../services/PointOfSale/PointOfSale.service';
import useUser from '../../Shared/UserProvider/useUser';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {  TABLET_THRESHOLD } from '../../../constants';
import { SiteStatus } from './SiteStatus'

const computePriceIncrease = (markup) => {
    const increaseValue = Math.fround((100 * markup) / (100 - markup))
    return `${increaseValue < 0 ? "-" : "+"}${increaseValue.toFixed(2)}`
}

const stringToFloat = (str,min,max) => {
    let parsable = str;
    if(str[str.length - 1] === ".") {
        parsable = str.slice(0, -1);
    }
    const parsed =  parseFloat(parsable || '0.0');
    if(isNaN(parsed)) return null;
    if(parsed < min) return min;
    if(parsed > max) return max;

    return parsed;
}

const PlatformItem = ({ posId, brandId, platform }) => {
    const [t] = useTranslation();
    const [form, setForm] = useState({
        active: false,
        autoAcceptOrder: false,
        markup: ''
    })
    const [loading, setLoading] = useState(false);
    const {user} = useUser();
    const isTablet = useMediaQuery(`(max-width: ${TABLET_THRESHOLD}px)`);


    const handleSubmit = async () => {
        notify.hide()
        setLoading(true);

        try {
            const updatedPosBrandPlatform = await patchPosBrandPlatform({
                posId,
                brandId,
                platformId: platform.id,
                active: form.active,
                autoAcceptOrder: form.autoAcceptOrder,
                markup: form.markup,
            });

            setForm({
                active: updatedPosBrandPlatform.active,
                autoAcceptOrder: updatedPosBrandPlatform.autoAcceptOrder,
                markup: updatedPosBrandPlatform.markup,
            });

            notify.show(t('global.registerSuccess'), 'success');
        } catch {
            notify.show(t('global.anErrorOccurred'), 'error');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        setForm({
            active: platform.active,
            autoAcceptOrder: platform.autoAcceptOrder,
            markup: platform.markup
        })
    }, [platform]);

    return (
        <Item key={platform.id}>
            <Item.Image src={platform.logo} />

            <Item.Content>
                <Item.Header css={css`width: 100%;`}>
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        `}
                    >
                        {platform.name}
                        {platform.externalData?.siteStatus && <SiteStatus css={css`
                            margin-right: .75rem;
                        `} siteStatus={platform.externalData.siteStatus} />}
                    </div>
                </Item.Header>

                <Item.Description css={css`
                  padding-top: 1rem;
                `}>
                    <Form>
                        {user.isStaff && (
                          <Form.Field>
                            <Checkbox checked={form.active}
                              label={t('administration.platforms.active')}
                              toggle
                              onChange={(evt, dt) =>
                                setForm(prev => ({ ...prev, active: dt.checked }))} />
                          </Form.Field>
                        )}
                        <Form.Field>
                            <Checkbox checked={form.autoAcceptOrder}
                                      label={t('pos_brand_platform.auto_accept_orders')}
                                      toggle
                                      onChange={(evt, dt) =>
                                          setForm(prev => ({ ...prev, autoAcceptOrder: dt.checked }))} />
                        </Form.Field>
                        <Form.Field width={isTablet ? undefined : 7}>
                            <label>{t('administration.platforms.markup')}</label>
                            <Input
                                   label={{ basic: true, content: '%' }}
                                   labelPosition='right'
                                   name="markup"
                                   type="number"
                                   placeholder='0.00'
                                   value={form.markup}
                                   onChange={(evt, dt) => setForm(prev => ({ ...prev, markup: dt.value !== "" ? stringToFloat(dt.value || '0.0',-99,99) : "" }))}
                                   />
                            {form.markup ? (<><span css={css`font-style: italic; font-size:0.75em; padding-left:2px`}>{t('markup.equivalent_to_price_increase_of')}</span> <strong>{computePriceIncrease(form.markup)}%</strong></>) : undefined}
                        </Form.Field>

                    </Form>
                </Item.Description>
                <Item.Extra>
                    <div style={{ textAlign: 'right' }}>
                        <Button color="teal" type="submit" loading={loading} disabled={loading} onClick={handleSubmit}>{t('global.save')}</Button>
                    </div>
                </Item.Extra>
            </Item.Content>
        </Item>
    )
}

export default PlatformItem
