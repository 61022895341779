/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { Step } from 'semantic-ui-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { MOBILE_THRESHOLD } from '../../../constants'

const OrderStatusBar = ({status, onStatusChange}) => {
    const [t] = useTranslation()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)

    if (['RECEIVED', 'DENIED', 'CANCELLED'].includes(status)) {
        return null
    }

    const getStepProps = (stepStatus, nextStatuses) => {
        const isActive = status === stepStatus
        const isCompleted = nextStatuses.includes(status) || status === 'COMPLETED'

        return {
            active: isActive,
            completed: isCompleted,
            onClick: () => isActive || isCompleted ? undefined : onStatusChange({status: stepStatus}),
        }
    }

    const steps = [
        { status: 'ACCEPTED', next: ['PREPARING', 'READY', 'COMPLETED'], label: t('order.status.ACCEPTED') },
        { status: 'PREPARING', next: ['READY', 'COMPLETED'], label: t('order.status.PREPARING') },
        { status: 'READY', next: ['COMPLETED'], label: t('order.status.READY') },
        { status: 'COMPLETED', next: [], label: t('order.status.COMPLETED') },
    ]

    return (
      <Step.Group unstackable ordered widths={4} css={isMobile ? css`
          z-index: 1;

          .step {
              display: flex !important;
              flex-direction: column !important;

              &::before {
                  margin-right: 0 !important;
                  margin-bottom: 1rem !important;
              }

              .content {
                  text-align: center;
                  height: 100%;
                  display: flex !important;
                  flex-direction: row;
                  align-items: center;

                  .title {
                      font-size: 0.9em !important;
                  }
              }
          }
      ` : css``}>
          {steps.map(({ status, next, label }) => (
            <Step key={status} {...getStepProps(status, next)}>
                <Step.Content>
                    <Step.Title>{label}</Step.Title>
                </Step.Content>
            </Step>
          ))}
      </Step.Group>
    )
}

export { OrderStatusBar }
