/** @jsxImportSource @emotion/react */
import {css} from '@emotion/react';
import React, {createRef, useContext, useEffect, useMemo, useState} from 'react';
import {Checkbox, Divider, Grid, Header,Image,Label, Segment, Statistic, Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Barcode from 'react-barcode';
import {useTranslation} from 'react-i18next';
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery';
import {UltyMoney} from '@ulty/ulty-ui';
import useUser from '../../../../Shared/UserProvider/useUser';
import {MOBILE_THRESHOLD} from '../../../../../constants';
import OrderItemRow from '../OrderItemRow';
import { useWebPSupportCheck } from "react-use-webp-support-check";
import { useOrderPickingScan } from '../../OrderScanPicking';
import { ScanInfo } from '../../OrderScanPicking/ScanInfo';
import { formatToMaxLength } from '../../../../../helpers/formatToMaxLength';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'


const OrderItemsRich = ({ order, orderItemsWithFulfillmentIssue, orderItemsReplacements, isModifying, onAddOrderItemToFulfillmentIssues, hideable }) => {
    const [t] = useTranslation();
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`);
    const supportsWebP = useWebPSupportCheck();

    const [checked, setChecked] = useState(new Set());

    const {pickingMode, scannedItemsMap,latestScannedItem} = useOrderPickingScan();
        
    useEffect(() => {
        setChecked(alreadyChecked => new Set([
            ...alreadyChecked,
            ...order.orderItems.filter(i => i.quantity === 0).map(i => i.id)
        ]))
    }, [order.orderItems]);


    useEffect(() => {
        if(pickingMode === 'SCANNING'){

            const orderItemsWithFulfillmentIssueSetToZero = orderItemsWithFulfillmentIssue.filter(oi => oi.quantity === 0);
            if(orderItemsWithFulfillmentIssueSetToZero.length > 0){
                setChecked(alreadyChecked => new Set([
                    ...alreadyChecked,
                    ...orderItemsWithFulfillmentIssueSetToZero.map(i => i.id)
                ]))
            }
       
        }
    }, [orderItemsWithFulfillmentIssue]);

    useEffect(() => {
        setChecked(alreadyChecked => new Set([
            ...order.orderItems.filter(i => i.quantity === 0).map(i => i.id)
        ]))
    }, [order.orderItems]);

    function createStateFromOrder(
        orderItems,
        scannedItemsMap,
        orderItemsWithFulfillmentIssue,
        currentMap=null) {
        const copied = new Map(currentMap);
        scannedItemsMap = scannedItemsMap ?? new Map();
        orderItems = orderItems ?? []

        return orderItems.reduce((acc, item) => {
            const currentItem = copied.get(item.id);
            const currentScanned = scannedItemsMap.get(item.id) || 0;
            const currentFulfillmentIssue = orderItemsWithFulfillmentIssue.find(oi => oi.id === item.id);
            acc.set(item.id, {
                ref: currentItem?.ref || createRef(),
                scanned: currentScanned,
                quantity: item.quantity,
                fulfillmentIssue: currentFulfillmentIssue
            });
            return acc;
        }, new Map());
    }
    
    const [itemsState, setItemsState] = useState(
        createStateFromOrder(order?.orderItems || [],scannedItemsMap,orderItemsWithFulfillmentIssue ?? [])
    );

    useEffect(() => {
        setItemsState((prev) => {
            return createStateFromOrder(
                order.orderItems ||[],scannedItemsMap,
                orderItemsWithFulfillmentIssue ||[],prev);
        })
    },[order?.orderItems, scannedItemsMap, orderItemsWithFulfillmentIssue]);

    useEffect(() => {
        if(pickingMode === 'SCANNING'){
            const fullyScannedItems = Array.from(itemsState.entries()).filter(([id, item]) => item.scanned === item.quantity).map(([id]) => id);
            setChecked(alreadyChecked => new Set([...alreadyChecked, ...fullyScannedItems])
            );
        }   
    },[itemsState])

    useEffect(() => {
        if(latestScannedItem && itemsState.has(latestScannedItem)){
            const item = itemsState.get(latestScannedItem);
            if(item?.ref){
                setTimeout(() => {
                    item.ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                })
            }
        }
    },[latestScannedItem])

    const getItemImage = (item) => {
        if (!item.image) {
            return '';
        }

        if (item.image.indexOf('images.ulty.fr') === -1) {
            return item.image;
        }

        const path = item.image.replace(/http[s]?:\/\/images.ulty.fr\//, '');
        if (supportsWebP) {
            return `https://thumbnails.ulty.fr/250x250/webp/${path}`;
        }
        return `https://thumbnails.ulty.fr/250x250/png/${path}`;
    }

    const ItemDescription = ({ item }) => {
        const maxLength = 50;
        let description = useMemo(() => {
            let desc = (item.object?.description || item.description);
            return desc?.length > maxLength ? formatToMaxLength(desc, maxLength) : desc;
        }, [item]);
        
        return (description && <>
        <div css={css`font-style: italic;font-size:0.75em`}>{description}</div>
        </> )
    }

    const OrderDetail = ({ item }) => {
        const {user} = useUser();

        return (
            <>
                <Divider horizontal>{t('order.order')}</Divider>
                <div css={css`
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  justify-content: space-around;
                  height: 100%;
                  max-height: 95px;
                  padding-bottom: 10px;

                  .statistic { margin: 0; flex: 1; }
                `}>
                    <Statistic size='mini'>
                        <Statistic.Label css={css`font-size: .8em !important;`} className={parseFloat(item.quantity||0) > 1 ? "multi-items" : ""}>{t('order.quantity')}</Statistic.Label>
                        <Statistic.Value className={parseFloat(item.quantity||0) > 1 ? "multi-items" : ""}>{item.quantity}</Statistic.Value>
                    </Statistic>
                    <Statistic size='mini'>
                        <Statistic.Label css={css`font-size: .8em !important;`}>{t('order.detail.unit_price')}</Statistic.Label>
                        <Statistic.Value css={css`font-size: 1.8rem !important`}>
                            <UltyMoney
                                readonly
                                amount={item.unitPrice}
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                            />
                        </Statistic.Value>
                    </Statistic>
                </div>
                <div css={css`
                  display: flex;
                  flex-direction: row;
                  width: 100%;
                  align-items: center;
                  justify-content: space-around;
                  height: 100%;
                  max-height: 95px;

                  .statistic { margin: 0; flex: 1; }
                `}>
                    { item.object?.barcode && (
                        <div css={css`flex: 2; text-align: center;`}>
                            <Barcode value={item.object.barcode} format="EAN13" displayValue={true} fontSize={15} height={60} width={1.5} />
                        </div>
                    )}
                </div>
            </>
        );
    }

    const buildModifiers = (modifiers, prefix = 0) => {
        return modifiers.map((m) => {
            let Pre = () => (<React.Fragment/>);
            if (m.modifiers && m.modifiers.length > 0) {
                Pre = () => buildModifiers(m.modifiers, prefix+1);
            }

            return (
                <React.Fragment key={m.id}>
                    <div style={{ paddingLeft: `${prefix*10}px`}}>{`- ${m.name} (${m.quantity}x)`}</div>
                    <Pre />
                </React.Fragment>
            )
        })
    }

    return (
        <>
            {order.orderItems.map((item,idx) => (
                <div key={item.id} ref={itemsState.get(item.id)?.ref} css={css`
                  margin-right: ${isMobile ? '-0.8rem' : '0'} !important;
                  margin-left: ${isMobile ? '-0.8rem' : '0'} !important;
                `}>
                    { hideable && (
                        <Header as='h5' attached='top' block css={css`
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          margin-top: 10px !important;

                          ${item.quantity === 0 && "text-decoration: line-through;"}
                        `}>
                            <div>{`#${idx+1} - ${item.object?.name||item.name}`} 
                            { scannedItemsMap.has(item.id) &&  <><br/><ScanInfo orderItem={item} scanValue={scannedItemsMap.get(item.id)} /> </>}
                            </div>
                            <div>
                                <Checkbox checked={checked.has(item.id)} onChange={(evt, { checked }) => setChecked(prev => {
                                    const copy = new Set(prev);
                                    if(copy.has(item.id) && !checked)
                                        copy.delete(item.id);
                                    else if(!copy.has(item.id) && checked){
                                        copy.add(item.id);
                                    }
                                    return copy;
                                })} />
                            </div>
                        </Header>
                    )}
                    { (!checked.has(item.id) || !hideable) && (
                        <Segment attached key={item.id}>
                            { item.categoryName && (
                                <>
                                    <Header as="h4">{item.categoryName}</Header>
                                    <Divider />
                                </>
                            )}
                            <Grid>
                                <Grid.Column tablet={8} css={css` ${isMobile ? 'padding-bottom: 0;' : ''}`}>
                                <Zoom>
                                    <Image src={getItemImage(item)} css={css` max-height: 250px; margin: auto; `} />
                                </Zoom>

                                </Grid.Column>
                                <Grid.Column tablet={8} css={css`
                                  display: flex !important;
                                  flex-direction: column;
                                  ${isMobile ? 'padding-bottom: 0;' : ''}

                                  label {
                                    font-weight: bold;
                                  }
                                `}>
                                    <Divider horizontal>{t('order.detail.product_id', { n: idx+1 })}</Divider>
                                    <div>{item.object?.name||item.name}</div>
                                    <ItemDescription item={item}/>
                                    {!isMobile && (
                                        <>
                                            {item.modifiers && buildModifiers(item.modifiers)}
                                            <OrderDetail item={item} />
                                        </>
                                    )}
                                </Grid.Column>

                                {isMobile && (
                                    <>
                                        { item.modifiers && item.modifiers.length > 0 && (
                                            <Grid.Column width={16} >
                                                {item.modifiers && buildModifiers(item.modifiers)}
                                            </Grid.Column>
                                        )}
                                        <Grid.Column width={16} css={css`
                                          padding-top: 0 !important;
                                          display: flex !important;
                                          flex-direction: column !important;
                                        `}>
                                            <OrderDetail item={item} />
                                        </Grid.Column>
                                    </>
                                )}

                                {isModifying &&
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Divider horizontal>{t('order.fulfillment_issue.fulfillment_issue')}</Divider>
                                            <Table>
                                                <Table.Body>
                                                    <OrderItemRow
                                                        {...itemsState.get(item.id)?.fulfillmentIssue && {currentQuantity: itemsState.get(item.id).fulfillmentIssue?.quantity}}
                                                        order={order}
                                                        orderItem={item}
                                                        displayOrderItemName={false}
                                                        isModifying={isModifying}
                                                        hasFulfillmentIssues={orderItemsWithFulfillmentIssue.length > 0}
                                                        addOrderItemToFulfillmentIssues={onAddOrderItemToFulfillmentIssues}
                                                        fulfillmentReplacement={orderItemsReplacements.find(op => op.fulfillmentIssueParentId === item.id)}
                                                    />
                                                </Table.Body>
                                            </Table>
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Segment>
                    )}
                    <Divider />
                </div>
            ))}
        </>
    );
}

OrderItemsRich.propTypes = {
    order: PropTypes.object.isRequired
}

export default OrderItemsRich;
