/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {
    Button, Header,
} from 'semantic-ui-react'
import { useCamera } from '../../../camera/useCamera'
import { useMutation } from '@tanstack/react-query'
import { postPickingProof } from '../../../services/Order/Order.service'
import { useTranslation } from 'react-i18next'
import { useId } from 'react'

const AskToAddPickingProof = ({orderId, afterSubmit, isFirstAsk, remainingPickingProofs}) => {
    const [t] = useTranslation()
    const postPickingProofMutation = useMutation({
        mutationFn: ({orderId, pickingProof}) => postPickingProof(orderId, pickingProof),
        onSettled: () => {
            setIsLoading(false)
            afterSubmit('CLOSE_AND_ASK_AGAIN')
        }
    })

    const cameraId = useId()
    const {takePhoto, isLoading, setIsLoading} = useCamera((image) => {
        postPickingProofMutation.mutate({orderId: orderId, pickingProof: image})
        setIsLoading(false)
    }, cameraId)

    return (
      <div>
          {isFirstAsk &&
              <Header>
                  {t('order.picking_proof.add_picking_proof_hint')}
                  <br />
                  <br />
                  {t('order.picking_proof.do_you_want_to_take_a_photo')}
              </Header>
          }

          {!isFirstAsk &&
            <Header>
                {t('order.picking_proof.photo_successfully_taken')}
                <br />
                <br />
                {t('order.picking_proof.take_another_picking_proof')}
            </Header>
          }

          <div>
              <Button
                fluid
                size='massive'
                loading={isLoading || postPickingProofMutation.isPending}
                disabled={isLoading || postPickingProofMutation.isPending}
                positive
                css={css`
                    &.ui.button {
                        margin-bottom: 1rem;
                    }
                `}
                onClick={() => {
                    setIsLoading(true)
                    takePhoto(cameraId)
                }}>
                  {t('global.yes')}
              </Button>

              <Button
                fluid
                size='massive'
                loading={isLoading || postPickingProofMutation.isPending}
                disabled={isLoading || postPickingProofMutation.isPending}
                onClick={() => {
                    afterSubmit('CLOSE_AND_CONTINUE')
                }}>
                  {t('global.no')}
              </Button>
          </div>

          <p css={css`
              font-style: italic;
              text-align: center;
          `}>
              {t('order.picking_proof.remaining_picking_proof', {count: remainingPickingProofs})}
          </p>
      </div>
    )
}

export { AskToAddPickingProof }
