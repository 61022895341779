/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useEffect, useState } from 'react'
import { Button, Header, Icon } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next'
import OrderStore from './Orders/OrderStore'
import useUser from './Shared/UserProvider/useUser'
import { openPosBrandPlatform } from '../services/PointOfSale/PointOfSale.service'
import { isProviderAPos } from '../services/Provider/Provider.service'
import { useMutation } from '@tanstack/react-query'

const PlatformError = () => {
    const [t] = useTranslation()
    const {user} = useUser()
    const store = OrderStore.useContainer()
    const [show, setShow] = useState(false)
    const openMutation = useMutation({
        mutationFn: ({posId, brandId, platformId}) => openPosBrandPlatform(posId, brandId, platformId),
        onSuccess: (data, variables) => {
            store.setPosBrandPlatformsInError(prev => prev.filter(pbp =>
                pbp.brand.id !== variables.brandId &&
                pbp.platform.id !== variables.platformId
            ))
        },
        onSettled: (data, error, variables) => {
            // TEMPORARY FIX: Ignore the error for 1 hour in case the integration is not reopened by the platform to avoid spamming the user (OPENING_HOURS, VACATION_MODE, etc.)
            localStorage.setItem(`ignore-pbp-in-error-${variables.id}`, new Date().toUTCString())
        },
    })

    const letPosBrandPlatformOpen = ({ id, brandId, platformId }) => {
        localStorage.setItem(`ignore-pbp-in-error-${id}`, new Date().toUTCString())
        store.setPosBrandPlatformsInError(prev => prev.filter(pbp =>
          pbp.brand.id !== brandId &&
          pbp.platform.id !== platformId
        ))
    }

    useEffect(() => {
        const posBrandPlatformsInError = store.posBrandPlatformsInError.length > 0
        setShow(posBrandPlatformsInError && isProviderAPos(user?.provider))
    }, [store.posBrandPlatformsInError, store.posBrandPlatformsInError.length, user?.provider])

    return (
      <>
          {show && (
            <div css={css`
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                z-index: 99999999;
                background-color: #ad0303;
                animation-name: color;
                animation-duration: 3s;
                animation-iteration-count: infinite;

                @keyframes color {
                    0% {
                        background-color: #ad0303;
                    }
                    50% {
                        background-color: #571d1d;
                    }
                    100% {
                        background-color: #ad0303;
                    }
                }

                h2 {
                    color: #fff !important;
                }
            `}>
                <Header as="h2" icon>
                    <Icon name="warning sign"/>
                    {t('platform.error_header')}
                </Header>
                {store.posBrandPlatformsInError.map(posBrandPlatformInError => (
                  <div key={posBrandPlatformInError.id} css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      margin: 1rem 0;
                  `}>
                      <Header as="h3" css={css`text-align: center;`}>
                          <Trans
                            i18nKey={'platform.your_store_is_closed'}
                            components={{
                                brand: <span style={{ color: 'white' }} />,
                                platform: <span style={{ color: 'white' }} />,
                            }}
                            values={{
                                brand: posBrandPlatformInError.brand.name,
                                platform: posBrandPlatformInError.platform.name
                            }}
                          />
                      </Header>

                      <div>
                          <Button
                            color="black"
                            loading={openMutation.isPending}
                            onClick={() => openMutation.mutate({
                                id: posBrandPlatformInError.id,
                                posId: posBrandPlatformInError.pos.id,
                                brandId: posBrandPlatformInError.brand.id,
                                platformId: posBrandPlatformInError.platform.id
                            })
                            }
                          >
                              {t('platform.reopen')}
                          </Button>
                          <Button
                            id="cancel-reopen-btn"
                            basic
                            color="grey"
                            css={css`
                                &#cancel-reopen-btn {
                                    font-weight: bold;
                                    color: white !important;
                                    box-shadow: 0 0 0 1px #fff inset !important;
                                }
                            `}
                            onClick={() => letPosBrandPlatformOpen({
                                id: posBrandPlatformInError.id,
                                brandId: posBrandPlatformInError.brand.id,
                                platformId: posBrandPlatformInError.platform.id
                            })}
                          >
                              {t('platform.leave_closed')}
                          </Button>
                      </div>
                  </div>
                ))}
            </div>
          )}
      </>
    )
}

PlatformError.propTypes = {}

export default PlatformError
