import { useCallback, useRef } from 'react';

function useDuplicatedCallback(callback, delay = 250,dependencies = []) {
  const lastCallRef = useRef({ args: null, timestamp: 0 });

  return useCallback(
    (...args) => {
      const now = Date.now();
      const lastCall = lastCallRef.current;

      if (
        now - lastCall.timestamp < delay &&
        JSON.stringify(args) === JSON.stringify(lastCall.args)
      ) {
        // Prevent duplicate call
        return;
      }

      // Update the last call reference
      lastCallRef.current = { args, timestamp: now };

      // Call the original callback
      callback(...args);
    },
    [callback, delay,...dependencies]
  );
}

export default useDuplicatedCallback;