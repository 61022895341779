/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect } from 'react'
import { Tab } from 'semantic-ui-react'
import {
    Route,
    NavLink,
    Switch,
} from 'react-router-dom'

import ActivateBusyMode from './ActivateBusyMode'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import OrdersFinished from './OrdersFinished'
import OrdersCurrent from './OrdersCurrent'
import OrderDetail from './OrderDetail'
import useUser from '../Shared/UserProvider/useUser'
import { useTranslation } from 'react-i18next'
import { OrderDetailProvider } from './OrderDetail/OrderDetailContext'
import useBreadcrumb from '../NavBar/useBreadcrumb'
import { MOBILE_THRESHOLD } from '../../constants'
import OrdersUntreated from './OrdersUntreated'
import { isProviderAPos } from '../../services/Provider/Provider.service'
import { PickingProofsGallery } from './PickingProof/PickingProofsGallery'

const OrderDetailWithProvider = () => (
  <OrderDetailProvider>
      <OrderDetail/>
  </OrderDetailProvider>
)

const OrdersIndex = () => {
    const [t] = useTranslation()
    const {user} = useUser()
    const {setPaths} = useBreadcrumb()
    const isMobile = useMediaQuery(`(max-width: ${MOBILE_THRESHOLD}px)`)
    const prefix = isMobile ? '.mobile' : ''

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/',
        }, {
            text: t('breadcrumb.orders'),
            link: false,
        }])
    }, [])

    const panes = [{
        menuItem: {
            as: NavLink,
            id: 'finished',
            content: t(`order${prefix}.finished`),
            to: '/orders/finished',
            key: 'finished',
        },
        pane: (
          <Route
            path="/orders/finished"
            key="finished"
            render={() => (
              <OrdersFinished/>
            )}
          />
        ),
    }, {
        menuItem: {
            as: NavLink,
            id: 'untreated',
            content: t(`order${prefix}.untreated`),
            to: '/orders/untreated',
            key: 'untreated',
        },
        pane: (
          <Route
            path="/orders/untreated"
            key="untreated"
            render={() => (
              <OrdersUntreated/>
            )}
          />
        ),
    }]

    if (isProviderAPos(user.provider)) {
        panes.unshift({
            menuItem: {
                as: NavLink,
                id: 'current',
                content: t(`order${prefix}.current`),
                to: '/orders/current',
                key: 'current',
            },
            pane: (
              <Route
                path="/orders/current"
                key="current"
                render={() => (
                  <OrdersCurrent/>
                )}
              />
            ),
        })
    }

    return (
      <>
          {user?.provider?.object?.company?.id !== '92b73161-e6ab-4581-b603-63bbe27b6c55' && <ActivateBusyMode/>}
          <Switch>
              <Route path="/orders/:id(\d+)/picking-proofs" component={PickingProofsGallery}/>
              <Route path="/orders/:id(\d+)" component={OrderDetailWithProvider}/>
              <Tab css={css`
                  margin-top: 20px;
                  margin-bottom: 20px;
              `} renderActiveOnly={false} activeIndex={-1} panes={panes}
                   menu={{secondary: true, pointing: true, className: 'wrapped'}}/>
          </Switch>
      </>
    )
}

export default OrdersIndex
