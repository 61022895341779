import {call, METHODS, rawCall} from '../http-client';
import {filtersToQueryString} from '../Filter/filter.service';

const ROUTE_PREFIX = 'item';

export const getItemImages = async (itemId) => {
    return await call(METHODS.GET, ROUTE_PREFIX, `${itemId}/images`);
}

export const defineItemImage = async (itemId, itemImage) => {
    return await call(METHODS.POST, ROUTE_PREFIX, `${itemId}/images`, {itemImage});
}

export const removeItemImages = async (itemId) => {
    return await call(METHODS.DELETE, ROUTE_PREFIX, `${itemId}/images`);
}

export const exportSearchItems = async(filters) => {
    let queryParams = filtersToQueryString(filters);

    const response = await rawCall(
        METHODS.POST,
        ROUTE_PREFIX,
        `export-search${queryParams}`,
        undefined,
        {
            responseType: 'arraybuffer'
        });

    return response;
}
export const countMerchantItems = async (filters) => {
    let queryParams = filtersToQueryString(filters);

    if (!queryParams) {
        queryParams = '?';
    }

    const response = await call(
        METHODS.GET,
        `${ROUTE_PREFIX}/count`,
        queryParams)


    return response?.count;
}
export const getMerchantItems = async (filters,cancellationSource=null) => {
    let queryParams = filtersToQueryString(filters);

    if (!queryParams) {
        queryParams = '?';
    }

    const response = await call(
        METHODS.GET,
        ROUTE_PREFIX,
        queryParams,
        cancellationSource?.patchAxiosOptions({}));

    const deserialize = (response) => {
        return {
            ...response,
            items: (response.items ? response.items : (Array.isArray(response) ? response : [])).map(item => ({
                ...item,
                inventories: (item.inventories || []).map(inventory => ({
                    ...inventory,
                    unavailableUntil: inventory.unavailableUntil === 'Infinity' ? Infinity : inventory.unavailableUntil
                }))
            }))
        }
    }

    return deserialize(response);
}

export const archive = async (id, patch) => {
    return await call(METHODS.PATCH, ROUTE_PREFIX, `${id}/archive`, patch );
}

export const isItemProvidedByUserProvider = (item, user) => {
    const providerItemIds = item.providers.map(provider => provider.id)
    return providerItemIds.includes(user.provider.id)
}

export const getItemCategories = async (itemId) => {
    return await call(METHODS.GET, ROUTE_PREFIX, `${itemId}/categories`);
}

export const removeItemCategory = async (itemId, categoryId) => {
    return await call(METHODS.DELETE, ROUTE_PREFIX, `${itemId}/categories/${categoryId}`);
}

export const addItemCategory = async (itemId, category) => {
    return await call(METHODS.POST, ROUTE_PREFIX, `${itemId}/categories`, category);
}

export const uploadFile = async (formData) => {
    return await call(METHODS.POST, ROUTE_PREFIX, 'upload', formData);
}

export const getPosItemSyncStates = async (itemId) => {
    return call(METHODS.GET, ROUTE_PREFIX, `${itemId}/syncStates`);
}

