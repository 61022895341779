/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import {useState, useEffect, useCallback, memo, useContext} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {Button, Divider, Item, Form, Header, Icon, Image, Label, List, Segment} from 'semantic-ui-react';
import { getFulfillmentAlternatives } from '../../../../services/Order/Order.service';
import {UltyInputText, UltyMoney} from '@ulty/ulty-ui';
import useUser from '../../../Shared/UserProvider/useUser';
import ItemSelector from '../../../Items/ItemElements/ItemSelector';
import { OrderDetailContext } from '../OrderDetailContext';

const ItemReplacementLabel = memo(({reason,platform}) => {
    const [t] = useTranslation();

    return (
        (reason === 'customer_request' ?  <Label size="tiny" color='orange'>{t('order.substitution.reasons.customer_request')}</Label>
            : reason === 'platform_recommandation' ?  <Label size="tiny" color='teal'>{t('order.substitution.reasons.platform_recommandation',{platform})}</Label>
            : <></>)
    )



}) 


const OrderItemReplacement = ({
    order,
    orderItem,
    addOrderItemToFulfillmentIssues,
    afterSubmit,
    shallAutoSelectSubstitute=false,
    preloadedSearch = undefined,
    onExit = () => {},
}) => {
    const [t] = useTranslation();
    const [quantity, setQuantity] = useState(orderItem.quantity);
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [substitutionId, setSubstitutionId] = useState(null);
    const {user} = useUser();

    useEffect(() => {
        return () => {
            onExit?.();
        }
    },[onExit])

    const retrieveReplacement = useCallback(async ({search,page,size},cancellationSource) => {

        setLoading(true);
        let items = await getFulfillmentAlternatives({
            order,
            orderItem,
            needle:search,
            quantity:orderItem.quantity,
            page,
            size},cancellationSource);
        if (order.platform.module !== 'deliveroo') {
            setSelectedItem(null)
        }
        setLoading(false);

        return {items}

    },[order,orderItem])


    const currency = user.getCompanyCurrency();
    const locale = user.getCompanyLocale();


    const renderItem = useCallback((i) => ({
            unitprice: i.inventory.sellPrice||i.basePrice,
            substitutionid: i.substitutionId,
            content: (
                <Header key={`item-${i.id}`} as='h2' css={css`
                display: flex;
                `}>
                    <Image  src={i.image} loading="lazy" />
                    <div css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: space-between;
                    width: 100%;
                    padding-left: 1em;
                    font-size: 1rem;
                    line-height: 1.2em;
                    `}>
                        <Header.Content>
                            <ItemReplacementLabel reason={i.reason} platform={order.platform.name} />
                            {i.object.name}
                            </Header.Content>
                        <Header.Subheader css={css`
                        width: 100%;
                        max-width: 180px;
                        `}>
                            <div>
                            <Icon name='barcode' color='grey'/>{i.object?.barcode}
                            </div>
                            <div css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            `}>
                                <UltyMoney
                                    readonly
                                    amount={i.inventory.sellPrice || i.basePrice}
                                    currencyCode={currency}
                                    locale={locale}
                                />
                                { i.inventory?.quantity && (
                                    <span>
                    <Icon name='shop' /> {i.inventory?.quantity || 0}
                </span>
                                )}
                            </div>
                        </Header.Subheader>
                    </div>
                </Header>
            )
    }),[user]);

    const apply = () => {
        addOrderItemToFulfillmentIssues(orderItem, 0, {
            parent: orderItem.id,
            itemId: selectedItem.id,
            name: selectedOption.text,
            unitPrice: selectedOption.unitprice,
            quantity,
            substitutionId,
        });

        afterSubmit();
    }

    return (
        <div css={css`
          margin-top: 20px;

          div.visible.menu.transition {
            max-height: 50vh !important;
          }
        `}>
            <Segment>
                <div css={css`
                  font-size: .92857143em;
                  font-weight: bold;
                `}>{t('order.substitution.initial_item')}</div>
                <List>
                    <List.Item>
                        <List.Icon name='id card' />
                        <List.Content>{orderItem.object.name}</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='euro' />
                        <List.Content>
                            <UltyMoney
                                readonly
                                amount={orderItem.unitPrice * orderItem.quantity}
                                currencyCode={user.getCompanyCurrency()}
                                locale={user.getCompanyLocale()}
                            />
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Icon name='cart' />
                        <List.Content>{`${orderItem.quantity} ${t('order.substitution.units')}`}</List.Content>
                    </List.Item>
                </List>
            </Segment>

            {<>
                    <div css={css`
                      font-size: .92857143em;
                      font-weight: bold;
                      margin-top: 20px;
                    `}>{t('order.substitution.replacement_item')}</div>
                    <div css={css`margin-top: 10px;`}>
                        <ItemSelector
                            preloadedSearch={preloadedSearch}
                            selectFirstAtLoad={shallAutoSelectSubstitute}
                            placeholder={t('order.substitution.choose_item')}
                            clearable={false}
                            onItemChange={(item,option) => {
                                setSelectedItem(item);
                               
                                if(!item){
                                    setSubstitutionId(null);
                                    setQuantity(orderItem.quantity);
                                    return;
                                }

                                setSelectedOption(option);
                                setSubstitutionId(option.substitutionid);

                                setQuantity(orderItem.quantity)

                            }}
                            searchFunction={retrieveReplacement}
                            itemRenderer={renderItem}
                        >

                        </ItemSelector>

                    </div>
                </>}

            <div css={css`
              font-size: .92857143em;
              font-weight: bold;
              margin-top: 20px;
            `}>{t('order.substitution.quantity')}</div>
            <div css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 10px;
              margin-bottom: 20px;
            `}>
                <Button
                    icon
                    disabled={quantity <= 1}
                    onClick={() => setQuantity(q => q - 1)}
                >
                    <Icon name="minus" />
                </Button>
                <div css={css`
                  padding-left: 20px;
                  padding-right: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  div {
                    font-weight: bold;
                  }
                `}>
                    {quantity}
                </div>
                <Button
                    icon
                    disabled={!selectedItem || (substitutionId && quantity >= orderItem.quantity) || !selectedOption || (orderItem.quantity <= (quantity+1))}
                    onClick={() => setQuantity(q => q + 1)}
                >
                    <Icon name="plus" />
                </Button>
            </div>

            { selectedOption && (
                <>
                    <Segment>
                        <div css={css`
                          font-size: .92857143em;
                          font-weight: bold;
                        `}>{t('order.substitution.substituted')}</div>
                        <List css={css`
                          font-weight: bold;
                        `}>
                            <List.Item>
                                <List.Icon name='id card' />
                                <List.Content>{selectedOption.text}</List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='euro' />
                                <List.Content>
                                    <UltyMoney
                                        readonly
                                        amount={selectedOption.unitprice * quantity}
                                        currencyCode={user.getCompanyCurrency()}
                                        locale={user.getCompanyLocale()}
                                    />
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Icon name='cart' />
                                <List.Content>{`${quantity} ${t('order.substitution.units')}`}</List.Content>
                            </List.Item>
                        </List>
                    </Segment>
                </>
            )}

            <Divider />

            <div css={css`
              margin-top: 20px;
              text-align: right;
            `}>
                <Button disabled={selectedItem === null} loading={loading} color="teal" onClick={() => apply()}>{t('global.validate')}</Button>
            </div>
        </div>
    );
};

OrderItemReplacement.propTypes = {
    order: PropTypes.object.isRequired,
    orderItem: PropTypes.object.isRequired,
    addOrderItemToFulfillmentIssues: PropTypes.func.isRequired,
    afterSubmit: PropTypes.func.isRequired,
    shallAutoSelectSubstitute: PropTypes.bool,
    preloadedSearch: PropTypes.string
};

export default OrderItemReplacement;
