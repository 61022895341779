/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, {useCallback, useEffect, useState} from 'react';
import {Button, Divider, Dropdown, Form, Icon, Segment, Table} from 'semantic-ui-react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {getPausedStores} from '../../services/Metrics/Metrics.service';
import { useTranslation } from 'react-i18next';
import useBreadcrumb from '../NavBar/useBreadcrumb';
import AdvancedFilters from './AdvancedFilters';
import useUser from '../Shared/UserProvider/useUser';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { reopenMerchantFromCompany } from '../../services/Company/Company.service';
import { getCancellationSource } from '../../services/http-client';
import humanizeDuration from '../../helpers/humanizeDuration'
import { ExportAllCompanyOpeningButton } from '../Shared/ExportAllCompanyOpeningButton/ExportAllCompanyOpeningButton';


const PausedStores = ({ handleFiltersChange, defaultFilters }) => {
    const [t] = useTranslation();
    const { setPaths } = useBreadcrumb();
    const [loading, setLoading] = useState(false);

    const [pausedMetrics, setPausedMetrics] = useState([]);
    const [filter, setFilter] = useState({
      isOpen: null,
      status: null
    });

    const {user} = useUser();

    const loadMetrics = useCallback(async (cancellationSource) => {
        setLoading(true);
        const data = await getPausedStores({
            merchantId: defaultFilters.merchantId,
            brandIds: defaultFilters.brandIds,
            platformIds: defaultFilters.platformIds,
        }, cancellationSource
        );

        if(cancellationSource?.isCancelled) return;

        setPausedMetrics(data?.merchants || []);
        setLoading(false);
    }, [defaultFilters.merchantId, defaultFilters.brandIds, defaultFilters.platformIds]);

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: false,
            path: '/'
        }]);
    }, [setPaths, t]);

    useEffect(() => {
        const cancellationSource = getCancellationSource();
        loadMetrics(cancellationSource);

        return () => cancellationSource.cancel();
    }, [loadMetrics]);

    const [localFilters, setLocalFilters] = useState({
        merchant: defaultFilters.merchant,
        brands: defaultFilters.brands,
        platforms: defaultFilters.platforms,
        dateRange: {
            startDate: moment(defaultFilters.dateRange.startDate).utc(),
            endDate: moment(defaultFilters.dateRange.endDate).utc()
        },
        dateComparison: {
            startDate: defaultFilters.dateComparison.startDate && moment(defaultFilters.dateComparison.startDate).utc(),
            endDate: defaultFilters.dateComparison.endDate && moment(defaultFilters.dateComparison.endDate).utc()
        }
    });

    const localHandleFiltersChange = (filters) => {
        setLocalFilters(prev => ({
            ...prev,
            ...filters,
        }));
    }

    const reopenStore = async (merchantId) => {
        setLoading(true);
        await reopenMerchantFromCompany(user.companyId, merchantId, 'IDLE');
        await loadMetrics();
    }

    useEffect(() => {
        handleFiltersChange(localFilters);
    }, [localFilters, handleFiltersChange]);


    const nextOpeningLabel = (nextOpening) => {
      if(nextOpening === 'NEVER') return t('metrics.pausedMetrics.no_opening_configured');

      return t('metrics.pausedMetrics.opening_in', {
        dt: humanizeDuration(new Date(),nextOpening, {round: true, units: ['mo','d', 'h', 'm'],largest:true})
    })
    };

    return (

        <>
        {user.provider.type === 'COMPANY' && <>
            <ExportAllCompanyOpeningButton/>
            <Divider/>
        </> }
            <div css={css`
              display: flex;
              justify-content: space-between;

              .csv-btn {
                display: flex;
                align-items: center;
              }
            `}>
                <AdvancedFilters
                    defaultFilters={{
                        merchant: defaultFilters.merchant,
                    }}
                    onFiltersChange={localHandleFiltersChange}>
                    <Form css={css` display: flex;`}>
                        <Form.Group widths="equal">
                            {user.provider.type === 'COMPANY' && <AdvancedFilters.PosFilter />}
                        </Form.Group>
                    </Form>
                </AdvancedFilters>
                <CSVLink
                    className="csv-btn"
                    enclosingCharacter={'"'}
                    separator=";"
                    headers={[
                        { label: t('metrics.pausedMetrics.pos'), key: 'store' },
                        { label: t('metrics.pausedMetrics.isOpen'), key: 'isOpen' },
                        { label: t('metrics.pausedMetrics.inServiceAvailability'), key: 'isInServiceAvailability' },
                        { label: t('metrics.pausedMetrics.inOffDay'), key: 'isInOffDay' },
                        { label: t('metrics.pausedMetrics.nextOpening'), key: 'nextOpening' },
                    ]}
                    data={pausedMetrics.map(pausedMetric => ({
                        store: pausedMetric.name,
                        isOpen: !pausedMetric.isClosed ? t('global.yes') : t('global.no'),
                        isInServiceAvailability: pausedMetric.isInServiceAvailability ? t('global.yes') : t('global.no'),
                        isInOffDay: pausedMetric.isInOffDay ? t('global.yes') : t('global.no'),
                        nextOpening: ((pausedMetric.isClosed) && !pausedMetric.isPaused && pausedMetric.nextOpening && nextOpeningLabel(pausedMetric.nextOpening)) || ""
                    }))}
                    filename="export-opened.csv"
                >
                    <Button icon>
                        <Icon name="cloud download" />
                    </Button>
                </CSVLink>
            </div>
            <Segment loading={loading} basic css={css`
              width: 100%;
              overflow: auto;
              min-height: 500px;
            `}>
                <Table celled striped selectable compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell  textAlign="center">{t('home.open')}</Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell  textAlign="center">
                                <Dropdown
                                    value={filter.isOpen}
                                    onChange={(evt, { value }) => setFilter((filter) => ({...filter,isOpen : value !== "" ? value : null}))}
                                    options={[{
                                        text: t('global.yes'),
                                        value: true,
                                        key: 'Y'
                                    }, {
                                        text: t('global.no'),
                                        value: false,
                                        key: 'N'
                                    }]}
                                    clearable
                                    selection
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            <Dropdown
                                    value={filter.status}
                                    onChange={(evt, { value }) => setFilter((filter) => ({...filter,status :  value !== "" ? value : null}))}
                                    options={[{
                                        text: t('metrics.pausedMetrics.inOffDay'),
                                        value: 'inOffDay',
                                        key: 'inOffDay'
                                    }, {
                                        text: t('metrics.pausedMetrics.inServiceAvailability'),
                                        value: 'inServiceAvailability',
                                        key: 'inServiceAvailability'
                                    }, {
                                      text: t('metrics.pausedMetrics.no_opening_configured'),
                                      value: 'no_opening_configured',
                                      key: 'no_opening_configured'
                                  }]}
                                    clearable
                                    selection
                                />
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {pausedMetrics
                            .filter(({ isClosed,isInServiceAvailability,isInOffDay,nextOpening} ) => (
                                (filter.isOpen === null || ((!isClosed) === filter.isOpen) )
                                && (filter.status === null || (
                                  (filter.status === 'inServiceAvailability' && (isInServiceAvailability && !isInOffDay))
                                  || (filter.status === 'inOffDay' && isInOffDay)
                                  || (filter.status === 'no_opening_configured' && nextOpening === 'NEVER')
                                ))
                            ))
                            .map(({ name,id,isClosed,isInServiceAvailability,isInOffDay,nextOpening,isPaused }) => (
                                <Table.Row key={id}>
                                    <Table.Cell width={10} >{name}</Table.Cell>
                                    <Table.Cell width={2}  textAlign="center">
                                        <>
                                            {(!isClosed)? <Icon name="check circle" color="green" /> : <Icon name="times circle" color="red" />}
                                                                             </>
                                    </Table.Cell>
                                    <Table.Cell width={2} textAlign="center">
                                        <>
                                            {isInOffDay && <div>{t('metrics.pausedMetrics.inOffDay')}</div>}
                                            {isClosed && !isPaused  && nextOpening && (<div>
                                                {nextOpeningLabel(nextOpening)}</div>)}
                                            {!isClosed && isInServiceAvailability && <div>{t('metrics.pausedMetrics.inServiceAvailability')}</div>}
                                            {!isInOffDay && isPaused && isInServiceAvailability && <div>{t('metrics.pausedMetrics.inBusyMode')}</div>}
                                        </>
                                    </Table.Cell>
                                    <Table.Cell width={1} textAlign='center'>
                                        {!isInOffDay && isPaused && <Button loading={loading} disabled={loading} onClick={() => reopenStore(id)}>{t('home.reopen')}</Button>}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            </Segment>
        </>
    )
}

export default PausedStores;
