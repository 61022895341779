import { call, getFileContent, METHODS } from '../http-client'

const ROUTE_PREFIX = 'companies';

export const getMerchantsOfCompany = async (companyId) => {
    try {
        return await call(METHODS.GET, ROUTE_PREFIX, `${companyId}/pointofsales`);
    } catch (e) {
        console.error(`getMerchantsOfCompany error: ${e}`);
        throw e;
    }
}

export const reopenMerchantFromCompany = async (companyId, merchantId, status) => {
    return await call(METHODS.PUT, ROUTE_PREFIX, `${companyId}/pos/${merchantId}/busy`, {
        status
    } );
}

export const exportPosAvailabilitiesOfACompany = async (companyId) => {
    try {
        return await getFileContent(`${ROUTE_PREFIX}/${companyId}/exportPosAvailabilities`, {
            responseType: 'arraybuffer'
        })
    } catch (e) {
        console.error(`exportPosAvailabilitiesOfACompany error: ${e.message}`)
        throw e;
    }
}
