/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import toast from 'react-hot-toast';
import {notify} from 'react-notify-toast';

import { Button, Icon, Label } from 'semantic-ui-react';

import SoundPlayer from './SoundPlayer'

import { useSocket } from '../../services/socket'

import OrderStore from '../Orders/OrderStore'

import OrderNotification from './OrderNotification'
import { ReadyState } from 'react-use-websocket'
import useUser from '../Shared/UserProvider/useUser';
import { useTranslation } from 'react-i18next';
import UserStore from '../Auth/store'
import { UltyModalWrapperContext } from '../Shared/UltyModalWrapper/UltyModalWrapperContext';
import { usePrinter } from '../../printing/services/printer.service';
import { retriggerRegistration } from '../../devices/device.provider';

const Notification = ({ children }) => {
    const {logout} = UserStore.useContainer();
  const { handleUltyModalWrapper } = useContext(UltyModalWrapperContext);

    const {user} = useUser();
    const history = useHistory();

    const {printerInfo, printingOptions, printOrder} = usePrinter();

    const orderStore = OrderStore.useContainer();
    const socket = useSocket()
    const [t] = useTranslation();

    const [stopSound, setStopSound] = useState(false)

    const userAck = useCallback(() => {
        orderStore.setToasts([]);
        toast.dismiss();
        setStopSound(true);
    }, [orderStore]);

    const handleNotification = useCallback((data) => {
        setStopSound(false)

        socket.emit("acknowledge", {
            uid: user.id,
            order: data.order.id,
        });

        orderStore.reload();

        const { type, order } = data;

        toast(<OrderNotification order={order} type={type} stopSound={userAck} />, {
            id: 1,
            duration: Infinity,
            icon: (
                <div css={css`
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: space-evenly;
                `}>
                    <div>
                        <Link as="div" css={css` position: relative; padding: 5px; cursor: pointer;`} to='/orders/current' onClick={userAck}>
                            <Icon name="fire" color="yellow" disabled={orderStore.toasts.filter(t => t.type === 'NEW').length === 0} />
                            { orderStore.toasts.filter(t => t.type === 'NEW').length > 0 && (
                                <Label color='red' floating circular size="mini">
                                    {orderStore.toasts.filter(t => t.type === 'NEW').length}
                                </Label>
                            )}
                        </Link>
                    </div>
                    <div>
                        <Link as="div" css={css` position: relative; padding: 5px; cursor: pointer;`} to='/orders/untreated' onClick={userAck}>
                            <Icon name="ban" color="red" disabled={orderStore.toasts.filter(t => t.type === 'CANCELLED').length === 0} />
                            { orderStore.toasts.filter(t => t.type === 'CANCELLED').length > 0 && (
                                <Label color='red' floating circular size="mini">
                                    {orderStore.toasts.filter(t => t.type === 'CANCELLED').length}
                                </Label>
                            )}
                        </Link>
                    </div>
                </div>
            )
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket, user, orderStore.toasts])

    useEffect(() => {
        if (orderStore.toasts.length > 0) {
            handleNotification(orderStore.toasts[orderStore.toasts.length - 1]);
            if(printerInfo.isDeviceCompatible && printingOptions.printOnNewOrder) {
                for(const toast of orderStore.toasts){
                    if(toast.type === 'NEW'){
                        printOrder(toast.order);
                    }
                }
            }
        }
    }, [orderStore.toasts, handleNotification])

    useEffect(() => {
        if (socket.status === ReadyState.OPEN) {
            socket.off("order");
            socket.on("order", (data) => {
                orderStore.addToast(data);
            });

            socket.off("current");
            socket.on("current", (data) => {
                const { sync, opened, pausedAt, busyPrepTime, posBrandPlatformsInError }  = data;
                orderStore.setSync(sync === true);
                orderStore.setOpened(opened);
                orderStore.setPausedAt(pausedAt);
                orderStore.setBusyPrepTime(busyPrepTime);
                orderStore.setPosBrandPlatformsInError(posBrandPlatformsInError.filter(pbpInError => {
                    // ignore if the user has already ignored this error during the last hour
                    const ignoreDate = localStorage.getItem(`ignore-pbp-in-error-${pbpInError.id}`)
                    if (ignoreDate) {
                        const ignoreDateObject = new Date(ignoreDate)
                        ignoreDateObject.setHours(ignoreDateObject.getHours() + 1)
                        if (ignoreDateObject >= new Date()) {
                            return false
                        } else {
                            localStorage.removeItem(`ignore-pbp-in-error-${pbpInError.id}`)
                            return true
                        }
                    }

                    return true
                }));
            });

            socket.off("notifications");
            socket.on("notifications", (data) => {
                const { orders } = data;
                if (orders.length === 0) {
                    userAck();
                } else {
                    orderStore.setToasts(toasts => toasts.filter(t => orders.map(o => o.id).includes(t.order.id)));
                }
            });

            socket.off("messaging");
            socket.on("messaging", async (data) => {
                const {title,message,messageId} = data;

                const modalSettings = {
                    basic:true,
                    actions:[{
                        key: 'ok',
                        content: 'OK',
                        primary: true, size:'small',
                        labelPosition:'right',
                        icon:'checkmark'

                    }],
                    title: `${title}`,
                    centered:true,
                    component: <span css={css`
                    white-space: pre-wrap;
                        `}>{message}
                    </span>,

                };
                handleUltyModalWrapper(true, modalSettings);


            });

            socket.off("disconnect_user");
            socket.on("disconnect_user", () => {
                logout();
                window.Android && window.Android.resetToken();
                history.push('/auth/login')
            });

            socket.off("resetToken");
            socket.on("resetToken", () => {
                window.Android && window.Android.resetToken();
            });

            socket.off("sendToken");
            socket.on("sendToken", async () => {
                if (window.Android && window.Android.getToken) {
                    await retriggerRegistration();
                }
            });

            socket.off("lightReload");
            socket.on("lightReload", async () => {
                notify.show(
                    <div css={css`
                      display: flex;
                      flex-direction: column;

                      button {
                        margin-top: 1em !important;
                      }
                    `}>
                        {t('global.new_version')}
                        <Button size="normal" color="teal" onClick={() => {
                            window.location.reload();
                            notify.hide();
                        }}>{t('global.refresh')}</Button>
                    </div>, "error", -1
                );
            });

            socket.off("hardReload");
            socket.on("hardReload", async () => {
                window.location.reload();
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket.status])

    return (
        <>
            { <SoundPlayer playing={orderStore.toasts.length > 0 && !stopSound}/>}
            {children}
        </>
    )
}

export default Notification
