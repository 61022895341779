import React, {createContext, useCallback, useState} from 'react';

let OrderDetailContext;
const { Provider } = (OrderDetailContext = createContext({}));

const OrderDetailProvider = ({ children}) => {
    const [isModifying, setIsModifying] = useState(false);
    const [isInReplacement, setIsInReplacement] = useState(false);

    const startReplacement = useCallback(() => {
        setIsInReplacement(true);
    },[setIsInReplacement]);

    const endReplacement = useCallback(() => {
        setIsInReplacement(false);
    },[setIsInReplacement]);

    return (
        <Provider value={{isModifying, setIsModifying,isInReplacement,startReplacement,endReplacement}}>
            {children}
        </Provider>
    )
}

export { OrderDetailContext, OrderDetailProvider };
