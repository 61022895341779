/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import {
    Button, Dropdown,
    Grid,
    Icon,
    Input, Pagination,
    Segment,
} from 'semantic-ui-react'
import ProductUploadModal from './ProductUploadModal'
import { archive, exportSearchItems, getMerchantItems,countMerchantItems } from '../../../services/Item/Item.service'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import useUser from '../../Shared/UserProvider/useUser'
import { Link } from 'react-router-dom'
import useMediaQuery from 'beautiful-react-hooks/useMediaQuery'
import { exportProducts } from '../../../services/Product/Product.service'
import { saveFile } from '../../../services/File/File.service'
import { notify } from 'react-notify-toast'
import useBreadcrumb from '../../NavBar/useBreadcrumb'
import AdvancedFilters from './AdvancedFilters'
import { UltyModalWrapperContext } from '../../Shared/UltyModalWrapper/UltyModalWrapperContext'
import {
    filtersToQueryString,
    formatFilterValueFromType,
    useQuerySearchParams,
} from '../../../services/Filter/filter.service'
import UltyFilterBar from '../../Shared/UltyFilterBar/UltyFilterBar'
import MassActionBar from '../../Shared/MassActionBar/MassActionBar'
import MassActionConfirm from './MassActions/MassActionConfirm'
import { patchItemAvailability } from '../../../services/Provider/Provider.service'
import { getCategorySets } from '../../../services/Category/Category.service'
import { Sorting, SortingTypes } from './Sorting/Sorting'
import { ItemListSegment } from './ItemListSegment'


const itemLinkStyle = css`
    color: black;
    text-decoration: none;

    &:hover {
        color: black;
    }

    i {
        margin-right: .78571429rem !important;
    }
`

const itemListMassActions = {
    EDIT: 'edit',
    SET_AVAILABLE: 'setAvailable',
    SET_UNAVAILABLE: 'setUnavailable',
    SET_UNAVAILABLE_UNTIL_TONIGHT: 'setUnavailableUntilTonight',
    DELETE: 'delete',
}

const getSortingFromString = (str) => {
    if (!str) return undefined
    const splitted = str.toLowerCase().split('|')

    if (splitted.length !== 2) return undefined

    const [type, direction] = splitted
    if (!SortingTypes.includes(type)) return undefined

    return {
        type,
        direction: direction === 'desc' ? 'desc' : 'asc',
    }
}

const getSortingToString = (sorting) => {
    return sorting ? `${sorting.type}|${sorting.direction}` : undefined
}

const ItemsList = ({history}) => {
    const {user, can} = useUser()
    const {setPaths} = useBreadcrumb()
    const [t] = useTranslation()
    const {handleUltyModalWrapper} = useContext(UltyModalWrapperContext)

    const [selectedItems, setSelectedItems] = useState([])
    const [categories, setCategories] = useState([])

    const query = useQuerySearchParams()
    const queryFilterTypes = query.get('types')
    const queryFilterContainsAlcohol = JSON.parse(query.get('containsAlcohol'))
    const queryFilterImage = JSON.parse(query.get('withImage'))
    const queryFilterUnavailable = JSON.parse(query.get('unavailableUntil'))
    const queryFilterMaxQuantity = JSON.parse(query.get('maxQuantity'))
    const queryFilterCategorySet = query.get('categorySet')
    const queryFilterCategorySlot = query.get('categorySlot')
    const queryFilterCategory = query.get('category')
    const queryFilterCategoryExclusion = query.get('excludeCategories')
    const queryFilterSearch = query.get('search')
    const querySorting = query.get('sort')

    const queryPage = query.get("page");
    const queryPageSize = query.get("size");

    const queryClient = useQueryClient();

    const [pagination, setPagination] = useState({
        totalItems: 0,
        totalPages: 1,
        page: Number(query.get('page')) || 1,
        pageSize: Number(query.get('size')) || 30,
    })

    

    const {data:items,isFetching:itemsAreLoading} = useQuery({
        queryKey: ['items','search'],
        refetchOnWindowFocus:false,
        queryFn: async () => {
            
            const result  = await getMerchantItems({
                   ...filters,
                page: pagination.page,
                size: pagination.pageSize,
                sort: getSortingToString(sorting),
                noCount:true
            });
            return result?.items ?? []
        }
    });

    const {data:count,isFetching:countIsLoading} = useQuery({
        queryKey: ['items','search-count'],
        refetchOnWindowFocus:false,
        queryFn: () => countMerchantItems({
            ...filters,
        }),
    });

    const filters = useMemo(() => ({
        types: queryFilterTypes?.split(',') || undefined,
        containsAlcohol: queryFilterContainsAlcohol !== null ? queryFilterContainsAlcohol : undefined,
        withImage: queryFilterImage !== null ? queryFilterImage : undefined,
        unavailableUntil: queryFilterUnavailable !== null ? queryFilterUnavailable : undefined,
        maxQuantity: queryFilterMaxQuantity !== null ? queryFilterMaxQuantity : undefined,
        categorySet: queryFilterCategorySet !== null ? queryFilterCategorySet : undefined,
        categorySlot: queryFilterCategorySlot !== null ? queryFilterCategorySlot : undefined,
        category: queryFilterCategory !== null ? queryFilterCategory : undefined,
        excludeCategories: queryFilterCategoryExclusion !== null ? !!queryFilterCategoryExclusion : undefined,
        search: queryFilterSearch !== null ? queryFilterSearch : undefined,
    }), [queryFilterCategory, queryFilterCategoryExclusion, queryFilterCategorySet, queryFilterCategorySlot, queryFilterContainsAlcohol, queryFilterImage, queryFilterMaxQuantity, queryFilterSearch, queryFilterTypes, queryFilterUnavailable])



    const isMobile = useMediaQuery(`(max-width: 768px)`)
    //const [items, setItems] = useState([])
   

    const sorting = useMemo(() => {
        return getSortingFromString(querySorting)
    },[querySorting])

    const [searchField, setSearchField] = useState(filters.search)

    const [searchExportInProgress, setSearchExportInProgress] = useState(false)

    useEffect(() => {
        setPaths([{
            text: t('breadcrumb.home'),
            link: true,
            path: '/',
        }, {
            text: t('breadcrumb.catalog'),
            link: false,
            path: '/products',
        }])
    }, [t])

    const canStillSelectItems = useMemo(() => {
        return items && items.some(t => !selectedItems.map(t => t.id).includes(t.id))
    }, [selectedItems, items])

    useEffect(() => {
        (async function loadCategories() {
            try {
                const categories = await getCategorySets()
                setCategories(categories.map(set => ({
                    label: set.name,
                    value: set.id,
                    slots: set.slots.map(slot => ({
                        label: slot.name,
                        value: slot.id,
                        categories: slot.categories.map(category => ({
                            label: category.name,
                            value: category.id,
                        })),
                    })),
                })))
            } catch (e) {
                console.log(e)
                notify.show(`${t('global.anErrorOccurred')}`, 'error')
            }
        })()
    }, [t])

    const invalidateCountAndSearch = useCallback(() => {

        
        const promises = Promise.all([
            queryClient.invalidateQueries({queryKey: ["items", "search"]}),
            queryClient.invalidateQueries({queryKey: ["items", "search-count"]})
        ]);

        const cancellation = () => {
            queryClient.cancelQueries({queryKey: ["items", "search"]})
            queryClient.cancelQueries({queryKey: ["items", "search-count"]})

        }
        return {promises,cancellation}
    },[queryClient]);

    useEffect(() => {
        setPagination(p => ({
            ...p,
            page:Number(queryPage) || 1,
            pageSize:Number(queryPageSize) || 30,
        }));
        
        queryClient.invalidateQueries({queryKey: ["items", "search"]});
        return () => {
            queryClient.cancelQueries({queryKey: ["items", "search"]})

        }
        
    },[queryPage,queryPageSize,queryClient])


    useEffect(() => {
        const {cancellation} = invalidateCountAndSearch();

        return () => {
            cancellation()
        }
    }, [filters,invalidateCountAndSearch])



    useEffect(() => {
        if(!itemsAreLoading && !countIsLoading && items){
            const totalPages = Math.ceil(count / +(pagination.pageSize)) || 1;
            setPagination(p => ({
                ...p,
                totalItems: count,
                totalPages
            }))
        }
    },[itemsAreLoading,countIsLoading,items,count,pagination.pageSize])

    /*
    const loadItems = useCallback(() => {
        getMerchantItems({
            ...getFiltersFromQuery(),
            page: pagination.page,
            size: pagination.pageSize,
            sort: getSortingToString(sorting),
        })
          .then((merchantItems) => {
              if (merchantItems.items && merchantItems.pagination) {
                  setItems(merchantItems?.items)
                  setPagination(prev => ({...prev, ...merchantItems.pagination}))
              }
              setIsLoading(false)
          })
    }, [getFiltersFromQuery, pagination.page, pagination.pageSize, sorting])
*/
    const exportItems = useCallback(async () => {
        setSearchExportInProgress(true)
        try {
            const {data} = await exportSearchItems({
                ...filters,
                page: pagination.page,
                size: pagination.pageSize,
                sort: getSortingToString(sorting),
            })
            saveFile(t('category_set.export_search_file_name'), data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        } finally {
            setSearchExportInProgress(false)
        }

    }, [filters,pagination.page,pagination.pageSize,sorting])

    const handleSelectedItems = useCallback((item) => {
        setSelectedItems(prev => (
          prev.map(item => item.id).includes(item.id) ?
            prev.filter(selectedItem => selectedItem.id !== item.id) :
            [...prev, item]))
    }, [])


    const handleExportProducts = async () => {
        try {
            const {data} = await exportProducts()
            saveFile(t('product_list.export.export_products_file_name'), data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        } catch (e) {
            console.log(e)
            notify.show(t('global.anErrorOccurred'), 'error')
        }
    }

    const replaceHistory = useCallback((filters, pagination, sorting = undefined) => {
        history.replace({
            pathname: '/products',
            search: filtersToQueryString({
                ...filters,
                page: pagination.page,
                size: pagination.pageSize,
                sort: getSortingToString(sorting),
            }),
        })
    }, [history])

    const handleFiltersChange = useCallback((filters) => {
        replaceHistory(filters, {...pagination,page:1}, sorting)
        handleUltyModalWrapper(false)
    }, [handleUltyModalWrapper, pagination, sorting, replaceHistory])

    const onSearch = useCallback(async (value) => {
        if (value) {
            handleFiltersChange({...filters, search: value})
        } else {
            const {search, ...filtersWithoutSearch} = filters
            handleFiltersChange(filtersWithoutSearch)
        }
    }, [filters, handleFiltersChange])

    const debouncedSearch = useMemo(() => (
      debounce((value) => onSearch(value), 500)
    ), [onSearch])


    const handleSearchChange = async (e, {value}) => {
        setSearchField(value)
        debouncedSearch(value)
    }

    const handleOnPageChange = (e, paginationProps) => {
        setPagination({
            ...pagination,
            page: paginationProps.activePage,
        })

        replaceHistory(filters, {...pagination, page: paginationProps.activePage}, sorting)
    }

    const onPageSizeChange = (e, paginationProps) => {
        setPagination({
            ...pagination,
            page: 1,
            pageSize: paginationProps.value,
        })

        replaceHistory(filters, {page: 1, pageSize: paginationProps.value}, sorting)
    }

    const onSortingChange = (sorting) => {
        replaceHistory(filters, {...pagination, page: 1}, sorting)
        queryClient.invalidateQueries({queryKey: ['items','search']})
    }

    const handleOpenAdvancedFiltersModal = () => {
        const modalSettings = {
            component: <AdvancedFilters
              defaultFilters={filters}
              onFiltersChange={handleFiltersChange}
            />,
        }

        handleUltyModalWrapper(true, modalSettings)
    }

    const formatFilterData = (type, value) => {
        let formattedLabel
        let formattedValue

        switch (type) {
            case 'types':
                formattedValue = formatFilterValueFromType('array', type, value)
                formattedLabel = `${t('product_list.advanced_filters.general.item_types')} : ${formattedValue}`
                break
            case 'containsAlcohol':
                formattedValue = formatFilterValueFromType('boolean', type, {
                    raw: value,
                    true: t('product_list.advanced_filters.general.alcohol.with'),
                    false: t('product_list.advanced_filters.general.alcohol.without'),
                })
                formattedLabel = `${t('product_list.advanced_filters.general.alcohol.alcohol')} : ${formattedValue}`
                break
            case 'withImage':
                formattedValue = formatFilterValueFromType('boolean', type, {
                    raw: value,
                    true: t('product_list.advanced_filters.general.image.with'),
                    false: t('product_list.advanced_filters.general.image.without'),
                })
                formattedLabel = `${t('product_list.advanced_filters.general.image.image')} : ${formattedValue}`
                break
            case 'unavailableUntil':
                formattedValue = formatFilterValueFromType('boolean', type, {
                    raw: value,
                    true: t('product_list.advanced_filters.inventory.availability.unavailable'),
                    false: t('product_list.advanced_filters.inventory.availability.available'),
                })
                formattedLabel = `${t('product_list.advanced_filters.inventory.availability.availability')} : ${formattedValue}`
                break
            case 'maxQuantity':
                formattedValue = formatFilterValueFromType('primitive', type, value)
                formattedLabel = `${t('product_list.advanced_filters.inventory.quantity.available_quantity')} ${formattedValue}`
                break
            case 'categorySet': {
                const [cs] = categories.filter(c => c.value === value)
                if (cs) {
                    formattedValue = cs.label
                    formattedLabel = `${t('product_list.advanced_filters.menu.menu')} : ${formattedValue}`
                }
                break
            }
            case 'categorySlot': {
                const [cs] = categories.flatMap(c => c.slots).filter(c => c.value === value)
                if (cs) {
                    formattedValue = cs.label
                    formattedLabel = `${t('product_list.advanced_filters.menu.submenu')} : ${formattedValue}`
                }
                break
            }
            case 'category': {
                const [cs] = categories.flatMap(c => c.slots).flatMap(c => c.categories).filter(c => c.value === value)
                if (cs) {
                    formattedValue = cs.label
                    formattedLabel = `${t('product_list.advanced_filters.menu.category')} : ${formattedValue}`
                }
                break
            }
            case 'excludeCategories': {
                formattedLabel = `${t('product_list.advanced_filters.menu.without_category')}`
                break
            }
            case 'search': {
                formattedValue = formatFilterValueFromType('primitive', type, value)
                formattedLabel = `${t('product_list.advanced_filters.search')} : "${formattedValue}"`
                break
            }
            default:
                formattedLabel = ''
        }

        return formattedLabel
    }

    const handleClearSelection = () => {
        setSelectedItems([])
        handleUltyModalWrapper(false, null)
    }

    const handleSelectAll = () => {
        setSelectedItems(items)
    }

    const handleViewSelection = () => {
        const modalSettings = {
            title: t('mass_action_bar.current_selection'),
            component: <MassActionConfirm
              selectedItems={selectedItems}
              onItemsChange={setSelectedItems}
              onMassAction={handleMassActionByKey}
              afterSubmit={() => handleUltyModalWrapper(false, null)}
            />,
        }

        handleUltyModalWrapper(true, modalSettings)
    }

    const performMassAction = async (promises, successMessage) => {
        try {
            await promiseAllInBatches(promises, 5)
            setSelectedItems([])
            const {promises:invalidations} = invalidateCountAndSearch();
            await invalidations();

            notify.show(successMessage, 'success')
        } catch (e) {
            console.log(e)
            notify.show(t('global.anErrorOccurred'), 'error')
        }
    }

    const promiseAllInBatches = async (fcts, batchSize) => {
        let position = 0
        let results = []
        while (position < fcts.length) {
            const fctsForBatch = fcts.slice(position, position + batchSize)
            results = [...results, ...await Promise.all(fctsForBatch.map(fct => fct()))]
            position += batchSize
        }
        return results
    }

    const handleMassActionSetAvailable = async (selectedItems) => {
        const promises = selectedItems.map(item => () => patchItemAvailability(user.provider.id, item.id, null))
        await performMassAction(promises, t('product_list.mass_actions.set_available.set_available_success'))
    }

    const handleMassActionSetUnavailable = async (selectedItems) => {
        const promises = selectedItems.map(item => () => patchItemAvailability(user.provider.id, item.id, 'infinity'))
        await performMassAction(promises, t('product_list.mass_actions.set_unavailable.set_unavailable_success'))
    }

    const handleMassActionSetUnavailableUntilTonight = async (selectedItems) => {
        const tomorrow = new Date()
        tomorrow.setUTCDate(tomorrow.getUTCDate() + 1)
        tomorrow.setUTCHours(0, 0, 0, 0)

        const promises = selectedItems.map(item => () => patchItemAvailability(user.provider.id, item.id, tomorrow))
        await performMassAction(promises, t('product_list.mass_actions.set_unavailable.set_unavailable_success'))
    }

    const handleMassActionDelete = async (items) => {
        const promises = items.map(item => () => archive(item.id, {archived: true}))
        await performMassAction(promises, t('product_list.mass_actions.delete.delete_success'))
    }

    const handleMassActionByKey = async (massActionKey, selectedItems) => {
        switch (massActionKey) {
            case itemListMassActions.SET_AVAILABLE:
                await handleMassActionSetAvailable(selectedItems)
                break
            case itemListMassActions.SET_UNAVAILABLE:
                await handleMassActionSetUnavailable(selectedItems)
                break
            case itemListMassActions.SET_UNAVAILABLE_UNTIL_TONIGHT:
                await handleMassActionSetUnavailableUntilTonight(selectedItems)
                break
            case itemListMassActions.DELETE:
                await handleMassActionDelete(selectedItems)
                break
            default:
                break
        }
    }

    const ItemPaging = () => {
        return <Segment basic textAlign="center">
            {!countIsLoading && (
            <Pagination
                firstItem={null}
                lastItem={null}
                pointing
                secondary
                siblingRange={isMobile ? 0 : 1}
                activePage={pagination.page}
                totalPages={pagination.totalPages}
                onPageChange={handleOnPageChange}
            />
            )}
        </Segment>
    }

    return (
      <>
          {(user.provider.type === 'COMPANY' || user.canCreateItems) && (
            <Segment basic css={css`
                text-align: right;
                padding-right: 0 !important;
            `}>
                {can('CREATE', 'items') &&
                  <Dropdown text={t('product_list.manage_products')} floating button basic
                            className="icon teal right labeled">
                      <Dropdown.Menu className="right">
                          <Dropdown.Item onClick={() => history.push('/products/edit')}>
                              <Icon name="plus" color="teal"/>
                              {t('product_list.add_product')}
                          </Dropdown.Item>
                          <Dropdown.Item>
                              <Link to={`/extras/edit`} css={itemLinkStyle}>
                                  <Icon name="plus" color="teal"/>
                                  {t('product_list.add_extra')}
                              </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                              <Link to={`/menus/edit`} css={itemLinkStyle}>
                                  <Icon name="plus" color="teal"/>
                                  {t('product_list.add_menu')}
                              </Link>
                          </Dropdown.Item>

                          <ProductUploadModal reload={() => {
                              invalidateCountAndSearch();
                          }}/>
                          <Dropdown.Item onClick={handleExportProducts}>
                              <Icon name="upload" color="teal"/>
                              {t('product_list.export.export_products')}
                          </Dropdown.Item>
                      </Dropdown.Menu>
                  </Dropdown>}
            </Segment>
          )}
          <Segment css={css`
              position: sticky !important;
              top: 65px;
              z-index: 10;
          `}>

              <Grid>
                  <Grid.Row css={css` padding-bottom: 0 !important; `}>
                      <Grid.Column width={6}>
                          <div css={css`
                              display: flex;
                              flex-wrap: wrap;
                              row-gap: 1rem;
                              justify-content: space-between;
                          `}>
                              <MassActionBar
                                selectionCount={selectedItems.length}
                                onViewSelection={handleViewSelection}
                                onClearSelection={handleClearSelection}
                                onSelectAll={handleSelectAll}
                                canStillSelectItems={canStillSelectItems}
                              />
                          </div>
                      </Grid.Column>
                      <Grid.Column width={10} textAlign="right">
                          <div css={css`
                              display: flex;
                              flex-grow: ${isMobile ? 1 : 0};
                              column-gap: .5rem;
                              justify-content: flex-end;
                          `}>
                              <Input
                                fluid={isMobile}
                                css={css`
                                    flex-grow: ${isMobile ? 1 : 0};
                                `}
                                icon="search"
                                placeholder={t('product_list.filters.filter_placeholder')}
                                value={searchField}
                                onChange={handleSearchChange}
                              />
                              <Button
                                icon="filter"
                                color={'teal'}
                                title={t('product_list.advanced_filters.advanced_filters')}
                                onClick={handleOpenAdvancedFiltersModal}
                              />
                          </div>
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2} css={css` padding-bottom: 0 !important; `}>
                      <Grid.Column verticalAlign="middle" textAlign="left">
                          {t('product_list.filters.items_per_page')} : &nbsp;&nbsp;
                          <Dropdown options={[
                              {key: '3', value: 3, text: '3'},
                              {key: '10', value: 10, text: '10'},
                              {key: '30', value: 30, text: '30'},
                              {key: '50', value: 50, text: '50'},
                              {key: '100', value: 100, text: '100'},
                          ]} value={pagination.pageSize} onChange={onPageSizeChange}/>

                      </Grid.Column>

                      <Grid.Column floated="left" css={css`
                          font-style: italic;
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: flex-end;
                      `} textAlign="right">
                      </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                      <Grid.Column verticalAlign="middle" textAlign="left">
                          <Sorting onSortChange={onSortingChange} defaultSort={sorting}></Sorting>
                      </Grid.Column>

                      <Grid.Column floated="left" css={css`
                          font-style: italic;
                          display: flex;
                          flex-direction: row;
                          align-items: center;
                          justify-content: flex-end;
                      `} textAlign="right">
                            {!countIsLoading && (<span css={css`
                                margin-right: 0.6em
                            `}>
                                {t('product_list.num_items', {n: `${pagination.totalItems}`})}

                            </span>)}
                          <Button as="a" title="Download" loading={searchExportInProgress} basic compact icon="download"
                                  onClick={exportItems} disabled={searchExportInProgress}></Button>

                      </Grid.Column>
                  </Grid.Row>
              </Grid>
          </Segment>

          <UltyFilterBar
            css={css`margin-bottom: 1rem;`}
            filterAttributes={filters}
            formatFilterDataFn={formatFilterData}
            onFilterRemoval={(k) => {
                const newFilters = {...filters}
                newFilters[k] = undefined
                handleFiltersChange(newFilters)
            }}
          />

          <ItemListSegment
            isLoading={itemsAreLoading}
            items={items}
            onSelectItem={handleSelectedItems}
            selectedItems={selectedItems}
          />

          <ItemPaging/>
      </>
    )
}

export default ItemsList
